const init = (el) => {
  const items = el.querySelectorAll('.product-detail-accordion__item')
  const openItem = activeItem => {
    items.forEach(item => {
      const button = item.querySelector('.product-detail-accordion__item__title')
      const content = item.querySelector('.product-detail-accordion__item__content')
      const contentInner = item.querySelector('.product-detail-accordion__item__content-inner')

      if (item === activeItem && !item.classList.contains('is-active')) {
        item.classList.add('is-active')
        button && button.setAttribute('aria-expanded', 'true')
        if (content) content.style.height = contentInner.clientHeight + 'px'

        const imageEls = item.querySelectorAll('img[data-src]')

        imageEls.forEach(imageEl => {
          imageEl.src = imageEl.getAttribute('data-src')
        })
      } else {
        item.classList.remove('is-active')
        button && button.setAttribute('aria-expanded', 'false')
        if (content) content.style.height = 0
      }
    })
  }

  items.forEach((item, index) => {
    if (index === 0) {
      openItem(item)
    }

    const button = item.querySelector('.product-detail-accordion__item__title')

    button && button.addEventListener('click', () => {
      openItem(item)
    })
  })

  window.addEventListener('resize', () => {
    const activeItem = el.querySelector('.product-detail-accordion__item.is-active')

    if (activeItem) {
      const content = activeItem.querySelector('.product-detail-accordion__item__content')
      const contentInner = activeItem.querySelector('.product-detail-accordion__item__content-inner')

      content.style.height = contentInner.clientHeight + 'px'
    }
  })

  const seeAllReviewsBtn = el.querySelector('.product-detail-accordion__item__all-reviews')

  if (seeAllReviewsBtn) {
    seeAllReviewsBtn.addEventListener('click', () => {
      document.getElementById('judgeme_product_reviews')?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    })
  }

  document.addEventListener('productColorChange', (event) => {
    const product = event.detail.product

    if (product) {
      const descriptionEl = el.querySelector('.js-product-detail-accordion-description')

      if (descriptionEl) {
        const descriptionContentEl = descriptionEl.querySelector('.product-detail-accordion__item__content-inner')

        if (product.node.descriptionHtml) {
          descriptionContentEl.innerHTML = product.node.descriptionHtml + '\n' + getSeoText(product.node)
          descriptionEl.classList.remove('hidden')
        } else {
          descriptionContentEl.innerHTML = ''
          descriptionEl.classList.add('hidden')
        }
      }

      const materialEl = el.querySelector('.js-product-detail-accordion-material')

      if (materialEl) {
        const descriptionContentEl = materialEl.querySelector('.product-detail-accordion__item__content-inner')

        if (product.node.materialInformation?.value && descriptionContentEl) {
          descriptionContentEl.innerHTML = product.node.materialInformation.value
          materialEl.classList.remove('hidden')
        } else {
          descriptionContentEl.innerHTML = ''
          materialEl.classList.add('hidden')
        }
      }

      const returnsEl = el.querySelector('.js-product-detail-accordion-returns')

      if (returnsEl) {
        const contentInviteSale = returnsEl.querySelector(".js-content-invite-sale");
        const contentDefault = returnsEl.querySelector(".js-content-default");

        if (window.gatedArchiveSale && window.gatedArchiveSale.isEnable() && product.node.tags.includes('_gated_archive_sale')) {
          contentInviteSale.style.display = "block";
          contentDefault.style.display = "none";
        } else {
          contentDefault.style.display = "block";
          contentInviteSale.style.display = "none";
        }
      }
    }
  })
}

const getSeoText = (product) => {
  let productTitle = product.title

  for (const text in ['Men', 'Women', 'Kids']) {
    if (productTitle.includes(text)) {
      const newProductTitle = productTitle.replace(text, '')

      if (productTitle.includes('Kids')) {
        productTitle = `${text}' ${newProductTitle}`
      } else {
        productTitle = `${text}'s ${newProductTitle}`
      }

      break
    }
  }

  return `<p>Shop the ${productTitle}</p>`
}

export default () => {
  document.querySelectorAll('.js-product-detail-accordion').forEach(el => {
    if (!el.classList.contains('is-initialized')) {
      el.classList.add('is-initialized')
      init(el)
    }
  })
}
