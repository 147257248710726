export default function getUrl(path = null) {
  const rootUrl = window.Shopify?.routes?.root ?? '/'

  if (!path || path === '/') {
    return rootUrl
  }

  if (rootUrl === '/') {
    return path
  }

  return `${rootUrl.replace(/\/*$/, '')}${path}`
}