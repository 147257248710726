export default function waitFor(conditionFunction, interval = 500, timeout = 5000) {
  return new Promise((resolve, reject) => {
    if (conditionFunction()) {
      resolve()
      return
    }

    let intervalId, timeoutId

    intervalId = setInterval(() => {
      if (conditionFunction()) {
        clearInterval(intervalId)
        clearTimeout(timeoutId)
        resolve()
      }
    }, interval)

    timeoutId = setTimeout(() => {
      clearInterval(intervalId)
      reject(new Error('Timeout exceeded'))
    }, timeout)
  })
}